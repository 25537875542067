/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
// import Cookies from 'js-cookie';
import Header from "./header"
import BurgerMenu from "./burger_menu"
import Footer from "./footer"
import Chat from '../components/chat/_chat'
// import Modal from "./modal/modal"
// import Video from "./video/video"

const Layout = ({children, _class='',chat=true,header_visible=false}) => {

  const [isMenuModels, setIsMenuModels] = useState(false);
  const [modalOpen, setModalOpen] = useState(false)

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  function handleMenuModels(isMenuModelActive){
    setIsMenuModels(isMenuModelActive)
  }

  // useEffect(() => {
  //   const hasOpenedModal = Cookies.get('hasOpenedModal');
  //   if (!hasOpenedModal) {
  //     setModalOpen(true);
  //   }
  // }, []);

  // const closeModal = () => {
  //   Cookies.set('hasOpenedModal', 'true');
  //   setModalOpen(false);
  // };

  return (
    <>
      {chat ? <Chat/> : null}
      <BurgerMenu isMenuModels={isMenuModels} />
      <Header handleMenuModels={handleMenuModels} siteTitle={data.site.siteMetadata.title} header_visible={header_visible}/>
      <div className="stage">
        <div className={`main ${_class}`}>{children}</div>
      </div>
      <Footer />
      {/* {
        modalOpen && <Modal closeModalHandler={closeModal} >
          <Video url="https://youtu.be/z65_rVOddTw" />
        </Modal>
      } */}
      <div id="modal-root" />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
